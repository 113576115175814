import React, { useState } from 'react';

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');

  const validateEmail = (email) => {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  const handleForgotPassword = () => {
    if (!email) {
      setError('Por favor, insira um e-mail.');
      return;
    }

    if (!validateEmail(email)) {
      setError('Por favor, insira um e-mail válido.');
      return;
    }
    
    setError(''); 
    console.log(`Enviando e-mail para: ${email}`);
  };

  return (
    <div>
      <h2>Esqueci minha senha</h2>

      <p>Insira o seu e-mail para receber o link de recuperação de senha.</p>

      <input
        type="email"
        placeholder="Digite seu e-mail"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />

      {error && <p style={{ color: 'red' }}>{error}</p>}

      <button onClick={handleForgotPassword}>Enviar</button>
    </div>
  );
};

export default ForgotPassword;
