import React, { useState } from 'react';
import Logo from './../../assets/images/logo-dourada.png';
import './styles.css';

export default function Landing() {

    return (
        <div>
            <div id="container">
                <div>
                    <img src={Logo} id="logo" />
                    <div id="shortly">em breve...</div>
                </div>
            </div>
        </div>
    );
}