import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import useTokenValidation from '../../services/checkTokenValidity';

const Financial = () => {
  useTokenValidation()
  const [Withdrawals, setWithdrawals] = useState([
    { data: '25/09/2024', valor: 'R$ 11,13', status: 'Sucesso' }
  ]);
  const [availableBalance, setAvailableBalance] = useState(20000); // Saldo disponível
  const [withdrawalAmount, setWithdrawalAmount] = useState(0); // Valor do saque
  const [showModal, setShowModal] = useState(false); // Controle do modal
  const [pendingBalance, setPendingBalance] = useState(1468709); // Saldo pendente

  const navigate = useNavigate();


  // Função para simular o saque
  const handleConfirmWithdrawal = () => {
    if (withdrawalAmount > 0 && withdrawalAmount <= availableBalance) {
      const newDate = new Date().toLocaleDateString();
      const newWithdrawal = {
        data: newDate,
        valor: `R$ ${withdrawalAmount.toFixed(2)}`,
        status: 'Sucesso'
      };

      // Atualiza o saldo disponível e adiciona o novo saque
      setAvailableBalance(prevBalance => prevBalance - withdrawalAmount);
      setWithdrawals(prevWithdrawals => [...prevWithdrawals, newWithdrawal]);

      // Fechar o modal e resetar o valor do saque
      setShowModal(false);
      setWithdrawalAmount(0);
    } else {
      alert("Valor inválido ou saldo insuficiente.");
    }
  };

  return (
    <div style={styles.container}>
      <h2>Financeiro</h2>
      <div style={styles.saldoContainer}>
        <div style={styles.saldoBox}>
          <p>Saldo disponível</p>
          <h3>R$ {availableBalance.toFixed(2)}</h3>
        </div>
        <div style={styles.saldoBox}>
          <p>Saldo pendente</p>
          <h3>R$ {pendingBalance.toFixed(2)}</h3>
        </div>
      </div>

      <a href="#" style={styles.link}>Alterar minha conta para CNPJ</a>
      <button style={styles.button} onClick={() => setShowModal(true)}>Efetuar saque</button>

      <table style={styles.table}>
        <thead>
          <tr>
            <th>DATA</th>
            <th>VALOR</th>
            <th>STATUS</th>
          </tr>
        </thead>
        <tbody>
          {Withdrawals.map((Withdrawals, index) => (
            <tr key={index}>
              <td>{Withdrawals.data}</td>
              <td>{Withdrawals.valor}</td>
              <td><span style={styles.success}>{Withdrawals.status}</span></td>
            </tr>
          ))}
        </tbody>
      </table>

      <div style={styles.pagination}>
        <span>Exibindo 1 de 1 página</span>
        <div style={styles.pageButtons}>
          <button>&lt;</button>
          <span>1</span>
          <button>&gt;</button>
        </div>
      </div>

      {/* Modal para o saque */}
      {showModal && (
        <div style={styles.modalOverlay}>
          <div style={styles.modal}>
            <div style={styles.modalHeader}>
              <h3>Realizar saque</h3>
              <button style={styles.closeButton} onClick={() => setShowModal(false)}>×</button>
            </div>
            <p>O dinheiro cairá na sua conta em até 1 dia útil.</p>

            <div style={styles.modalBody}>
              <label>Valor do saque</label>
              <input
                type="number"
                value={withdrawalAmount}
                onChange={(e) => setWithdrawalAmount(parseFloat(e.target.value))}
                style={styles.input}
              />
              <p>Disponível: R$ {availableBalance.toFixed(2)}</p>

              <div style={styles.infoBox}>
                <span>Chave Pix: 70407522638</span>
              </div>
              <div style={styles.infoBox}>
                <span>🛈 Nós cobramos uma taxa de R$ 3,67 por saque.</span>
              </div>
            </div>

            <div style={styles.modalFooter}>
              <button style={styles.cancelButton} onClick={() => setShowModal(false)}>
                Cancelar
              </button>
              <button style={styles.confirmButton} onClick={handleConfirmWithdrawal}>
                Confirmar
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const styles = {
  container: {
    maxWidth: '1000px',
    margin: '0 auto',
    padding: '20px',
    backgroundColor: '#f4f4f9',
    borderRadius: '8px',
  },
  saldoContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '20px',
  },
  saldoBox: {
    flex: 1,
    margin: '0 10px',
    padding: '20px',
    backgroundColor: 'white',
    borderRadius: '8px',
    textAlign: 'center',
    boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
  },
  link: {
    color: '#007bff',
    textDecoration: 'none',
    marginBottom: '20px',
    display: 'inline-block',
  },
  button: {
    display: 'block',
    padding: '10px 20px',
    backgroundColor: '#007bff',
    color: 'white',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    marginBottom: '20px',
  },
  table: {
    width: '100%',
    borderCollapse: 'collapse',
    marginBottom: '20px',
  },
  success: {
    color: 'green',
    fontWeight: 'bold',
  },
  pagination: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  pageButtons: {
    display: 'flex',
    alignItems: 'center',
  },
  modalOverlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  modal: {
    backgroundColor: 'white',
    padding: '20px',
    borderRadius: '8px',
    width: '400px',
    boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
  },
  modalHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '10px',
  },
  closeButton: {
    background: 'none',
    border: 'none',
    fontSize: '20px',
    cursor: 'pointer',
  },
  modalBody: {
    marginBottom: '20px',
  },
  input: {
    width: '100%',
    padding: '10px',
    border: '1px solid #ccc',
    borderRadius: '4px',
    marginBottom: '10px',
    boxSizing: 'border-box',
  },
  infoBox: {
    backgroundColor: '#f0f4ff',
    padding: '10px',
    borderRadius: '4px',
    marginBottom: '10px',
    color: '#007bff',
    fontSize: '14px',
  },
  modalFooter: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  cancelButton: {
    padding: '10px 20px',
    backgroundColor: '#ccc',
    color: 'black',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
  },
  confirmButton: {
    padding: '10px 20px',
    backgroundColor: '#6200ee',
    color: 'white',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
  }
};

export default Financial;
