import React, { useState, useEffect } from 'react';
import axios from 'axios'; 
import { useNavigate } from 'react-router-dom'; 

export default function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState('');
  const [rememberMe, setRememberMe] = useState(false);
  const navigate = useNavigate(); 

  const validateEmail = (email) => {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  const handleLogin = async (e) => {
    e.preventDefault();

    if (!email) {
      setError('Por favor, insira um e-mail.');
      return;
    }

    if (!validateEmail(email)) {
      setError('Por favor, insira um e-mail válido.');
      return;
    }

    if (!password) {
      setError('Por favor, insira uma senha.');
      return;
    }

    setError('');

    try {
      // Faz a requisição de login para a API
      const response = await axios.post('https://api.hest.com.br/api/user/login', {
        email,
        password,
      });

      if (response.status === 200 && response.data.token) {
        const token = response.data.token; 
        localStorage.setItem('token', token); 

        console.log('Login bem-sucedido, token salvo:', token);
        
        navigate('/verification');
      } else {
        setError('Erro ao fazer login. Token não recebido.');
      }
    } catch (error) {
      console.log('Erro no login:', error.response ? error.response.data : error.message);
      setError('Erro ao fazer login. Verifique seus dados e tente novamente.');
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const navigateToRegister = () => {
    navigate('/register'); 
  };

  return (
    <div>
      <h2>Login</h2>
      <form onSubmit={handleLogin}>
        <div>
          <label htmlFor="email">Email</label>
          <br />
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Digite seu email"
          />
        </div>
        <div>
          <label htmlFor="password">Senha</label>
          <br />
          <div>
            <input
              type={showPassword ? 'text' : 'password'}
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Digite sua senha"
            />
            <button type="button" onClick={togglePasswordVisibility}>
              {showPassword ? 'Ocultar' : 'Mostrar'}
            </button>
          </div>
        </div>

        {/* Opção de manter logado */}
        <div>
          <input
            type="checkbox"
            id="rememberMe"
            checked={rememberMe}
            onChange={(e) => setRememberMe(e.target.checked)}
          />
          <label htmlFor="rememberMe">Manter logado</label>
        </div>

        {error && <p style={{ color: 'red' }}>{error}</p>}

        <div>
          <a href="/forgot">Esqueci minha senha</a>
        </div>

        <button type="submit">Entrar</button>
      </form>

      <div>
        <p>Ainda não tem login?</p>
        <button type="button" onClick={navigateToRegister}>
          Cadastrar-se
        </button>
      </div>
    </div>
  );
}
