import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import useTokenValidation from '../../services/checkTokenValidity';
const Products = () => {
  useTokenValidation()
  // Estado com lista de produtos em solicitação
  const [products, setProducts] = useState([
    { id: 1, name: 'Produto A', status: 'Pendente' },
    { id: 2, name: 'Produto B', status: 'Pendente' },
    { id: 3, name: 'Produto C', status: 'Aprovado' },
    { id: 4, name: 'Produto D', status: 'Cancelado' }
  ]);


  // Funções para aprovar e reprovar o produto
  const approveProduct = (id) => {
    setProducts(products.map(product => 
      product.id === id ? { ...product, status: 'Aprovado' } : product
    ));
  };

  const rejectProduct = (id) => {
    setProducts(products.map(product => 
      product.id === id ? { ...product, status: 'Cancelado' } : product
    ));
  };

  return (
    <div style={styles.container}>
      <h2>Produtos em Solicitação</h2>
      <table style={styles.table}>
        <thead>
          <tr>
            <th style={styles.idColumn}>ID</th>
            <th style={styles.nameColumn}>Nome do Produto</th>
            <th style={styles.statusColumn}>Status</th>
            <th style={styles.actionColumn}>Ações</th>
          </tr>
        </thead>
        <tbody>
          {products.map((product) => (
            <tr key={product.id}>
              <td>{product.id}</td>
              <td>{product.name}</td>
              <td>
                <span
                  style={
                    product.status === 'Pendente'
                      ? styles.pending
                      : product.status === 'Aprovado'
                      ? styles.approved
                      : styles.cancelled
                  }
                >
                  {product.status}
                </span>
              </td>
              <td>
                {product.status === 'Pendente' && (
                  <>
                    <button style={styles.approveButton} onClick={() => approveProduct(product.id)}>
                      Aprovar
                    </button>
                    <button style={styles.rejectButton} onClick={() => rejectProduct(product.id)}>
                      Reprovar
                    </button>
                  </>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

const styles = {
  container: {
    maxWidth: '1000px',
    margin: '0 auto',
    padding: '20px',
    backgroundColor: '#f9f9f9',
    borderRadius: '8px',
    boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
  },
  table: {
    width: '100%',
    borderCollapse: 'collapse',
    marginTop: '20px',
  },
  idColumn: {
    width: '10%',
    textAlign: 'left',
  },
  nameColumn: {
    width: '40%',
    textAlign: 'left',
  },
  statusColumn: {
    width: '20%',
    textAlign: 'left',
  },
  actionColumn: {
    width: '30%',
    textAlign: 'left',
  },

  pending: {
    color: 'orange',
    fontWeight: 'bold',
  },
  approved: {
    color: 'green',
    fontWeight: 'bold',
  },
  cancelled: {
    color: 'red',
    fontWeight: 'bold',
  },
  approveButton: {
    backgroundColor: 'green',
    color: 'white',
    border: 'none',
    padding: '5px 10px',
    borderRadius: '5px',
    cursor: 'pointer',
    marginRight: '5px',
  },
  rejectButton: {
    backgroundColor: 'red',
    color: 'white',
    border: 'none',
    padding: '5px 10px',
    borderRadius: '5px',
    cursor: 'pointer',
  },
};

export default Products;
