import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from "./pages/login";
import Register from "./pages/register";
import Verification from "./pages/verification";
import Dashboard from "./pages/dashboard";
import Forgot from "./pages/forgot";
import Marketplace from "./pages/marketplace";
import Checkout from "./pages/checkout";
import Affiliation from "./pages/affiliation";
import Solicitation from "./pages/solicitation";
import Profile from "./pages/profile";
import Financial from "./pages/financial";
import Products from "./pages/products";
import Details from "./pages/details";
import Landing from "./pages/landing";

export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Landing />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/verification" element={<Verification />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/forgot" element={<Forgot />} />
        <Route path="/marketplace" element={<Marketplace />} />
        <Route path="/checkout" element={<Checkout />} />
        <Route path="/solicitation" element={<Solicitation />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/financial" element={<Financial />} />
        <Route path="/products" element={< Products />} />
        <Route path="/details" element={< Details />} />
        <Route path="/affiliation/:id" element={<Affiliation />} />
      </Routes>
    </BrowserRouter>
  );
}