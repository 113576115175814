import React, { useState } from 'react';
import axios from 'axios';

export default function Checkout() {
  const [name, setName] = useState('');
  const [cpf, setCpf] = useState('');
  const [email, setEmail] = useState('');
  const [code, setCode] = useState('');
  const [birthdate, setBirthdate] = useState('');
  
  const [cardNumber, setCardNumber] = useState('');
  const [cardHolder, setCardHolder] = useState('');
  const [cardExpiryMonth, setCardExpiryMonth] = useState('');
  const [cardExpiryYear, setCardExpiryYear] = useState('');
  const [cvv, setCvv] = useState('');

  const [cep, setCep] = useState('');
  const [addressLine1, setAddressLine1] = useState('');
  const [addressLine2, setAddressLine2] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  
  const [phoneAreaCode, setPhoneAreaCode] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');

  const estadosDoBrasil = [
    { nome: 'Acre', sigla: 'AC' },
    { nome: 'Alagoas', sigla: 'AL' },
    { nome: 'Amapá', sigla: 'AP' },
    { nome: 'Amazonas', sigla: 'AM' },
    { nome: 'Bahia', sigla: 'BA' },
    { nome: 'Ceará', sigla: 'CE' },
    { nome: 'Distrito Federal', sigla: 'DF' },
    { nome: 'Espírito Santo', sigla: 'ES' },
    { nome: 'Goiás', sigla: 'GO' },
    { nome: 'Maranhão', sigla: 'MA' },
    { nome: 'Mato Grosso', sigla: 'MT' },
    { nome: 'Mato Grosso do Sul', sigla: 'MS' },
    { nome: 'Minas Gerais', sigla: 'MG' },
    { nome: 'Pará', sigla: 'PA' },
    { nome: 'Paraíba', sigla: 'PB' },
    { nome: 'Paraná', sigla: 'PR' },
    { nome: 'Pernambuco', sigla: 'PE' },
    { nome: 'Piauí', sigla: 'PI' },
    { nome: 'Rio de Janeiro', sigla: 'RJ' },
    { nome: 'Rio Grande do Norte', sigla: 'RN' },
    { nome: 'Rio Grande do Sul', sigla: 'RS' },
    { nome: 'Rondônia', sigla: 'RO' },
    { nome: 'Roraima', sigla: 'RR' },
    { nome: 'Santa Catarina', sigla: 'SC' },
    { nome: 'São Paulo', sigla: 'SP' },
    { nome: 'Sergipe', sigla: 'SE' },
    { nome: 'Tocantins', sigla: 'TO' }
  ];


  const handleCpfChange = (e) => {
    const cpfValue = e.target.value.replace(/\D/g, ''); // Remove tudo que não for número
    setCpf(cpfValue);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(cpf)
    const customerData = {
      items: 1,
      customer: {
        address: {
          country: 'BR',
          state: state,
          city: city,
          zip_code: cep,
          line_1: addressLine1,
          line_2: addressLine2
        },
        phones: {
          mobile_phone: {
            country_code: '55',
            area_code: phoneAreaCode,
            number: phoneNumber
          }
        },
        name: name,
        type: 'individual',
        email: email,
        document: cpf,
        document_type: 'CPF',
        birthdate: birthdate
      },
      payments: [
        {
          credit_card: {
            card: {
              holder_document: cpf,
              number: cardNumber,
              holder_name: cardHolder,
              exp_month: cardExpiryMonth,
              exp_year: cardExpiryYear,
              cvv: cvv
            },
            operation_type: 'auth_and_capture',
            installments: 1,
            statement_descriptor: 'hest'
          },
          payment_method: 'credit_card'
        }
      ]
    };

    console.log(customerData); // Verifique no console se o objeto está correto

    try {
      const response = await axios.post('https://api.hest.com.br/api/payments/credit', customerData, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      console.log('Resposta da API:', response.data);
    } catch (error) {
      console.error('Erro ao enviar os dados:', error);
    }
  };

  return (
    <div>
      <h2>Checkout</h2>
      <div style={{ border: '1px solid #ccc', padding: '20px', marginBottom: '20px' }}>
        <h3>Produto</h3>
        <img src="https://via.placeholder.com/150" alt="Produto" />
        <h4>Nome do Produto</h4>
        <p>Descrição do produto, características e outros detalhes relevantes.</p>
        <p>Preço: R$ 199,99</p>
      </div>
      <form onSubmit={handleSubmit}>
        <h3>Dados do Cliente</h3>
        <div>
          <label>Nome Completo:</label>
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder="Nome Completo"
          />
        </div>
        <div>
          <label>CPF:</label>
          <input
            type="text"
            value={cpf}
            onChange={handleCpfChange}
            placeholder="CPF"
            maxLength="11"
          />
        </div>
        <div>
          <label>E-mail:</label>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="E-mail"
          />
        </div>
       
        <div>
          <label>Data de Nascimento:</label>
          <input
            type="date"
            value={birthdate}
            onChange={(e) => setBirthdate(e.target.value)}
          />
        </div>

        <h3>Endereço</h3>
        <div>
          <label>CEP:</label>
          <input
            type="text"
            value={cep}
            onChange={(e) => setCep(e.target.value)}
            placeholder="CEP"
          />
        </div>
        <div>
          <label>Endereço :</label>
          <input
            type="text"
            value={addressLine1}
            onChange={(e) => setAddressLine1(e.target.value)}
            placeholder="Endereço, número"
          />
        </div>
        <div>
          <label>Complemento:</label>
          <input
            type="text"
            value={addressLine2}
            onChange={(e) => setAddressLine2(e.target.value)}
            placeholder="Complemento"
          />
        </div>
        <div>
          <label>Cidade:</label>
          <input
            type="text"
            value={city}
            onChange={(e) => setCity(e.target.value)}
            placeholder="Cidade"
          />
        </div>
        <div>
          <label>Estado:</label>
          <select
            value={state}
            onChange={(e) => setState(e.target.value)}
          >
            <option value="">Selecione o Estado</option>
            {estadosDoBrasil.map((estado) => (
              <option key={estado.sigla} value={estado.sigla}>
                {estado.sigla}
              </option>
            ))}
          </select>
        </div>

        <h3>Telefone</h3>
        <div>
          <label>Código de Área:</label>
          <input
            type="text"
            value={phoneAreaCode}
            onChange={(e) => setPhoneAreaCode(e.target.value)}
            placeholder="Código de Área"
          />
        </div>
        <div>
          <label>Número:</label>
          <input
            type="text"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
            placeholder="Número de Telefone"
          />
        </div>

        <h3>Cartão de Crédito</h3>
        <div>
          <label>Número do Cartão:</label>
          <input
            type="text"
            value={cardNumber}
            onChange={(e) => setCardNumber(e.target.value)}
            placeholder="Número do Cartão"
          />
        </div>
        <div>
          <label>Nome no Cartão:</label>
          <input
            type="text"
            value={cardHolder}
            onChange={(e) => setCardHolder(e.target.value)}
            placeholder="Nome no Cartão"
          />
        </div>
        <div>
          <label>Mês de Expiração:</label>
          <input
            type="number"
            value={cardExpiryMonth}
            onChange={(e) => setCardExpiryMonth(e.target.value)}
            placeholder="Mês"
            min="1"
            max="12"
          />
        </div>
        <div>
          <label>Ano de Expiração:</label>
          <input
            type="number"
            value={cardExpiryYear}
            onChange={(e) => setCardExpiryYear(e.target.value)}
            placeholder="Ano"
            min="22"
            max="99"
          />
        </div>
        <div>
          <label>CVV:</label>
          <input
            type="text"
            value={cvv}
            onChange={(e) => setCvv(e.target.value)}
            placeholder="CVV"
            maxLength="3"
          />
        </div>

        <button type="submit">Finalizar Pagamento</button>
      </form>
    </div>
  );
}
