import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

export default function Register() {
  const [email, setEmail] = useState('');
  const [repetirEmail, setRepetirEmail] = useState('');
  const [senha, setSenha] = useState('');
  const [aceitarTermos, setAceitarTermos] = useState(false);
  const [mensagemErro, setMensagemErro] = useState('');
  const [mensagemSucesso, setMensagemSucesso] = useState('');
  const [senhaForca, setSenhaForca] = useState('');
  const [mostrarSenha, setMostrarSenha] = useState(false);
  const navigate = useNavigate();

  const validarEmail = (email) => {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  const validarForcaSenha = (senha) => {
    let forca = '';
    if (senha.length < 6) {
      forca = 'Fraca';
    } else if (senha.length >= 6 && /[A-Z]/.test(senha) && /\d/.test(senha) && /[!@#$%^&*(),.?":{}|<>]/.test(senha)) {
      forca = 'Forte';
    } else {
      forca = 'Média';
    }
    setSenhaForca(forca);
  };

  const handleRegistro = async (e) => {
    e.preventDefault();

    if (email === '' || repetirEmail === '' || senha === '') {
      setMensagemErro('Todos os campos são obrigatórios.');
      return;
    } else if (!validarEmail(email)) {
      setMensagemErro('Por favor, insira um e-mail válido.');
      return;
    } else if (email !== repetirEmail) {
      setMensagemErro('Os e-mails não coincidem.');
      return;
    } else if (senhaForca !== 'Forte') {
      setMensagemErro('A senha deve ser forte para concluir o cadastro.');
      return;
    } else if (!aceitarTermos) {
      setMensagemErro('Você deve aceitar os termos de uso para continuar.');
      return;
    }

    setMensagemErro('');

    try {
      const data = {
        email: email,
        password: senha,
        password_confirmation: senha,
      };

      console.log('Dados enviados para a API:', data);

      const response = await axios.post('https://api.hest.com.br/api/user/register', data);

      console.log('Resposta da API:', response.data);

      if (response.status === 201) {
        console.log('Registro bem-sucedido, redirecionando...');
        setMensagemSucesso('Usuário registrado com sucesso!');
        navigate('/login'); // Redireciona corretamente para a página de login
      } else {
        console.log('Status inesperado:', response.status);
        setMensagemErro('Erro inesperado ao registrar o usuário.');
      }
    } catch (error) {
      console.log('Erro no registro:', error.response ? error.response.data : error.message);
      
      if (error.response && error.response.status === 422) {
        setMensagemErro('Erro ao registrar usuário: dados inválidos.');
      } else {
        setMensagemErro('Erro ao registrar usuário. Verifique os dados e tente novamente.');
      }
    }
  };

  const handleSenhaChange = (e) => {
    const novaSenha = e.target.value;
    setSenha(novaSenha);
    validarForcaSenha(novaSenha);
  };

  const toggleMostrarSenha = () => {
    setMostrarSenha(!mostrarSenha);
  };

  return (
    <div>
      <h2>Registro de Usuários</h2>
      <form onSubmit={handleRegistro}>
        <div>
          <label htmlFor="email">Email:</label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Digite seu e-mail"
          />
        </div>
        <div>
          <label htmlFor="repetirEmail">Repetir Email:</label>
          <input
            type="email"
            id="repetirEmail"
            value={repetirEmail}
            onChange={(e) => setRepetirEmail(e.target.value)}
            placeholder="Repita seu e-mail"
          />
        </div>
        <div>
          <label htmlFor="senha">Senha:</label>
          <input
            type={mostrarSenha ? 'text' : 'password'}
            id="senha"
            value={senha}
            onChange={handleSenhaChange}
            placeholder="Digite sua senha"
          />
          <button type="button" onClick={toggleMostrarSenha}>
            {mostrarSenha ? 'Ocultar' : 'Mostrar'} Senha
          </button>
        </div>
        <div>
          <p>Força da senha: <strong>{senhaForca}</strong></p>
          <progress
            value={senhaForca === 'Fraca' ? 30 : senhaForca === 'Média' ? 60 : 100}
            max="100"
          ></progress>
        </div>
        <div>
          <input
            type="checkbox"
            id="aceitarTermos"
            checked={aceitarTermos}
            onChange={(e) => setAceitarTermos(e.target.checked)}
          />
          <label htmlFor="aceitarTermos">
            Eu li e aceito os termos de uso, termos de licença de uso de software, política de conteúdo da
          </label>
        </div>
        <button type="submit">Registrar Usuário</button>
      </form>
      {mensagemErro && <p style={{ color: 'red' }}>{mensagemErro}</p>}
      {mensagemSucesso && <p style={{ color: 'green' }}>{mensagemSucesso}</p>}
    </div>
  );
}
