import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import useTokenValidation from '../../services/checkTokenValidity';

const Solicitation = () => {
  useTokenValidation()
  const [affiliations, setAffiliations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  

  // Função para buscar as solicitações de afiliação do usuário
  const fetchSolicitations = async () => {
    const token = localStorage.getItem('token'); // Recupera o token JWT do localStorage

    if (!token) {
      alert('Erro: Usuário não autenticado');
      return;
    }

    try {
      const response = await axios.get('https://api.hest.com.br/api/affiliations', {
        headers: {
          Authorization: `Bearer ${token}`, // Envia o token JWT no cabeçalho
          'Content-Type': 'application/json',
        },
      });

      console.log('Resposta da API:', response.data); // Verifica o que a API está retornando

      if (Array.isArray(response.data)) {
        setAffiliations(response.data); // Armazena os dados das afiliações no estado, se for um array
      } else {
        setAffiliations([]); // Se não for um array, defina um array vazio
      }

      setLoading(false); // Remove o carregamento
    } catch (error) {
      console.error('Erro ao carregar as solicitações de afiliação:', error.response ? error.response.data : error);
      setError('Erro ao carregar as solicitações.');
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchSolicitations(); // Chama a função ao carregar o componente
  }, []);

  if (loading) {
    return <p>Carregando solicitações...</p>;
  }

  if (error) {
    return <p>{error}</p>;
  }

  if (!Array.isArray(affiliations) || affiliations.length === 0) {
    return <p>Nenhuma solicitação de afiliação encontrada.</p>;
  }

  return (
    <div>
      <h1>Solicitações de Afiliação</h1>
      <table style={{ width: '100%', borderCollapse: 'collapse' }}>
        <thead>
          <tr>
            <th style={{ border: '1px solid black', padding: '8px' }}>Nome do Produto</th>
            <th style={{ border: '1px solid black', padding: '8px' }}>Categoria</th>
            <th style={{ border: '1px solid black', padding: '8px' }}>Preço</th>
            <th style={{ border: '1px solid black', padding: '8px' }}>Comissão</th>
            <th style={{ border: '1px solid black', padding: '8px' }}>Status</th>
          </tr>
        </thead>
        <tbody>
          {affiliations.map((affiliation) => (
            <tr key={affiliation.product_id}>
              <td style={{ border: '1px solid black', padding: '8px' }}>{affiliation.name}</td>
              <td style={{ border: '1px solid black', padding: '8px' }}>{affiliation.category}</td>
              <td style={{ border: '1px solid black', padding: '8px' }}>R$ {affiliation.price}</td>
              <td style={{ border: '1px solid black', padding: '8px' }}>{affiliation.commission}%</td>
              <td style={{ border: '1px solid black', padding: '8px' }}>
                {affiliation.status === 'pending' && <span style={{ color: 'orange' }}>Pendente</span>}
                {affiliation.status === 'approved' && <span style={{ color: 'green' }}>Aprovado</span>}
                {affiliation.status === 'cancelled' && <span style={{ color: 'red' }}>Cancelado</span>}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Solicitation;
