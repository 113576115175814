import React, { useState, useEffect } from 'react';
import axios from 'axios'; // Importa o axios
import { Link, useNavigate } from 'react-router-dom';
import useTokenValidation from '../../services/checkTokenValidity';

export default function Marketplace() {
  useTokenValidation()
  const [products, setProducts] = useState([]);
  const [priceFilter, setPriceFilter] = useState('');
  const [commissionFilter, setCommissionFilter] = useState('');
  const [categoryFilter, setCategoryFilter] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [loading, setLoading] = useState(true); // Para mostrar se está carregando
  const navigate = useNavigate();

  // Função para buscar os produtos da API usando axios
  const fetchProducts = async () => {
    try {
      const response = await axios.get('https://api.hest.com.br/api/products'); // Coloque aqui a URL da sua API
      setProducts(response.data); // Assume que o response.data contém o array de produtos
      setLoading(false);
    } catch (error) {
      console.error('Error fetching products:', error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchProducts();
  }, []); // Chama a função ao carregar a página

  const applyFilters = () => {
    let filteredProducts = [...products];

    // Filtro por preço
    if (priceFilter === 'menor-maior') {
      filteredProducts.sort((a, b) => a.preco - b.preco);
    } else if (priceFilter === 'maior-menor') {
      filteredProducts.sort((a, b) => b.preco - a.preco);
    }

    // Filtro por comissão
    if (commissionFilter) {
      filteredProducts = filteredProducts.filter(
        (product) => product.commission === parseInt(commissionFilter)
      );
    }

    // Filtro por categoria
    if (categoryFilter) {
      filteredProducts = filteredProducts.filter(
        (product) => product.category === categoryFilter
      );
    }

    // Filtro por nome (pesquisa)
    if (searchQuery) {
      filteredProducts = filteredProducts.filter((product) =>
        product.name.toLowerCase().includes(searchQuery.toLowerCase())
      );
    }

    setProducts(filteredProducts);
  };

  useEffect(() => {
    applyFilters();
  }, [priceFilter, commissionFilter, categoryFilter, searchQuery]);

  if (loading) {
    return <p>Loading products...</p>; // Exibe enquanto carrega os produtos
  }

  return (
    <div>
      <h1>Best Sellers on Marketplace</h1>

      {/* Barra de busca */}
      <div>
        <input
          type="text"
          placeholder="Search product"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
      </div>

      {/* Filtros de ordenação */}
      <div>
        <label>Sort by Price: </label>
        <select
          value={priceFilter}
          onChange={(e) => setPriceFilter(e.target.value)}
        >
          <option value="">Select</option>
          <option value="menor-maior">Lowest to Highest</option>
          <option value="maior-menor">Highest to Lowest</option>
        </select>

        <label>Filter by Commission: </label>
        <select
          value={commissionFilter}
          onChange={(e) => setCommissionFilter(e.target.value)}
        >
          <option value="">Select</option>
          <option value="5">5%</option>
          <option value="8">8%</option>
          <option value="10">10%</option>
          <option value="12">12%</option>
          <option value="15">15%</option>
        </select>

        <label>Filter by Category: </label>
        <select
          value={categoryFilter}
          onChange={(e) => setCategoryFilter(e.target.value)}
        >
          <option value="">All Categories</option>
          <option value="Apps & Software">Apps & Software</option>
          <option value="Empreendedorismo Digital">Digital Entrepreneurship</option>
          <option value="Tecnologia da Informação">Information Technology</option>
          <option value="Design">Design</option>
        </select>
      </div>

      {/* Lista de produtos */}
      <div>
        {products.length > 0 ? (
          products.map((product) => (
            <div key={product.id}>
              <Link
                to={`/affiliation/${product.id}`} // Redireciona para a página de afiliação
                style={{ textDecoration: 'none', color: 'inherit' }}
              >
                <img src={product.image} alt={product.name} />
                <h3>{product.name}</h3>
                <p>By {product.seller}</p>
                <p>Earn up to R$ {product.price}</p>
              </Link>
            </div>
          ))
        ) : (
          <p>No products found</p>
        )}
      </div>
    </div>
  );
}
