import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom'; 
import axios from 'axios';
import useTokenValidation from '../../services/checkTokenValidity';

const Affiliation = () => {
  useTokenValidation()
  const { id } = useParams(); // Pega o ID do produto da URL
  const [product, setProduct] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [notes, setNotes] = useState(''); // Estado para armazenar as notas opcionais
  const navigate = useNavigate(); // Usado para redirecionar após a solicitação

  const fetchProduct = async () => {
    try {
      const response = await axios.get(`https://api.hest.com.br/api/products?id=${id}`);
      setProduct(response.data);
      setLoading(false);
    } catch (error) {
      setError('Erro ao carregar o produto.');
      setLoading(false);
    }
  };

  const handleAffiliationRequest = async () => {
    const token = localStorage.getItem('token'); 

    if (!token) {
      alert('Erro: Usuário não autenticado');
      return;
    }

    try {
      const response = await axios.post(
        'https://api.hest.com.br/api/affiliations',
        {
          product_id: id,  
          notes: notes     
        },
        {
          headers: {
            'Authorization': `Bearer ${token}`, 
            'Content-Type': 'application/json'
          }
        }
      );

      alert('Solicitação de afiliação aberta com sucesso!');
      navigate('/solicitation');
    } catch (error) {
      console.error('Erro ao abrir a solicitação:', error);
      alert('Erro ao abrir a solicitação.');
      console.log(error.response);
    }
  };

  if (loading) {
    return <p>Carregando produto...</p>;
  }

  if (error) {
    return <p>{error}</p>;
  }

  if (!product) {
    return <p>Produto não encontrado</p>;
  }

  return (
    <div>
      <h1>{product[0].name}</h1>
      <img src={product[0].image} alt={product[0].name} />
      <p>Vendedor: {product[0].seller}</p>
      <p>Categoria: {product[0].category}</p>
      <p>Preço: R$ {product[0].price}</p>

      {/* Campo para adicionar notas opcionais */}
      <textarea
        value={notes}
        onChange={(e) => setNotes(e.target.value)}
        placeholder="Adicionar notas (opcional)"
      />

      {/* Botão para abrir solicitação de afiliação */}
      <button onClick={handleAffiliationRequest}>
        Solicitar Afiliação
      </button>
    </div>
  );
};

export default Affiliation;
