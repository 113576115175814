import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import useTokenValidation from '../../services/checkTokenValidity';

export default function Verification() {
  useTokenValidation()
  const [codigo, setCodigo] = useState('');
  const [mensagemErro, setMensagemErro] = useState('');
  const [contador, setContador] = useState(60); // 60 segundos para o contador
  const [podeSolicitarNovamente, setPodeSolicitarNovamente] = useState(false);
  const navigate = useNavigate(); // Para redirecionar o usuário

  // Valida o token no localStorage ao carregar o componente
  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token) {
      alert('Token não encontrado. Faça login novamente.');
      navigate('/login'); // Redireciona para a página de login
    }
  }, [navigate]);

  // Contador para o botão de solicitar código novamente
  useEffect(() => {
    if (contador > 0) {
      const timer = setInterval(() => {
        setContador((prev) => prev - 1);
      }, 1000);
      return () => clearInterval(timer);
    } else {
      setPodeSolicitarNovamente(true);
    }
  }, [contador]);

  // Função para verificar o código inserido
  const handleVerificarCodigo = async (e) => {
    e.preventDefault();

    if (codigo.length !== 6) {
      setMensagemErro('O código de verificação deve conter 6 dígitos.');
      return;
    }

    try {
      const token = localStorage.getItem('token');
      if (!token) {
        setMensagemErro('Token não encontrado. Faça login novamente.');
        return;
      }

      const response = await axios.post(
        'https://api.hest.com.br/api/user/verification',
        { code: codigo }, // Envia o código inserido
        {
          headers: {
            Authorization: `Bearer ${token}`, // Envia o token no header
          },
        }
      );

      if (response.status === 200) {
        alert('Verificação bem-sucedida!');
        navigate('/dashboard'); // Redireciona para o dashboard
      } else {
        setMensagemErro('Código de verificação inválido.');
      }
    } catch (error) {
      console.log('Erro ao verificar o código:', error);
      setMensagemErro('Erro ao verificar o código. Tente novamente.');
    }
  };

  // Função para solicitar o código novamente
  const handleSolicitarCodigoNovamente = async () => {
    const token = localStorage.getItem('token');
    if (!token) {
      setMensagemErro('Token não encontrado. Faça login novamente.');
      return;
    }

    try {
      const response = await axios.post(
        'https://api.hest.com.br/api/user/resend-verification-code',
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`, // Envia o token no header para autenticação
          },
        }
      );

      if (response.status === 200) {
        setContador(60); // Reinicia o contador para 1 minuto
        setPodeSolicitarNovamente(false);
        setMensagemErro('');
        alert('Código de verificação reenviado!');
      } else {
        setMensagemErro('Erro ao solicitar o código novamente.');
      }
    } catch (error) {
      console.log('Erro ao solicitar o código novamente:', error);
      setMensagemErro('Erro ao solicitar o código. Tente novamente.');
    }
  };

  return (
    <div>
      <h2>Verificação de Código</h2>
      <form onSubmit={handleVerificarCodigo}>
        <div>
          <label htmlFor="codigo">Digite o código de verificação (6 dígitos):</label>
          <input
            type="text"
            id="codigo"
            value={codigo}
            onChange={(e) => setCodigo(e.target.value)}
            placeholder="Digite o código"
            maxLength={6} // Limita o campo a 6 dígitos
          />
        </div>
        <button type="submit">Verificar Código</button>
      </form>

      {mensagemErro && <p style={{ color: 'red' }}>{mensagemErro}</p>}

      <div>
        <p>Não recebeu o código?</p>
        <button
          type="button"
          onClick={handleSolicitarCodigoNovamente}
          disabled={!podeSolicitarNovamente} // Desativa o botão até que o contador termine
        >
          {podeSolicitarNovamente ? 'Solicitar código novamente' : `Tente novamente em ${contador}s`}
        </button>
      </div>
    </div>
  );
}
