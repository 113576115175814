import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import useTokenValidation from '../../services/checkTokenValidity';

const ProductDetails = () => {
  useTokenValidation()
  const [approvedProducts] = useState([
    { id: 1, name: 'Produto A', checkoutLink: 'http://localhost:3000/checkout?product=ProdutoA' },
    { id: 2, name: 'Produto C', checkoutLink: 'http://localhost:3000/checkout?product=ProdutoC' },
  ]);

  const navigate = useNavigate();

  // Função para copiar o link do checkout
  const copyLink = (link) => {
    navigator.clipboard.writeText(link);
    alert('Link copiado para a área de transferência!');
  };

  return (
    <div style={styles.container}>
      <h2>Produtos Aprovados</h2>
      {approvedProducts.length > 0 ? (
        <table style={styles.table}>
          <thead>
            <tr>
              <th>ID</th>
              <th>Nome do Produto</th>
              <th>Link de Vendas</th>
              <th>Ações</th>
            </tr>
          </thead>
          <tbody>
            {approvedProducts.map((product) => (
              <tr key={product.id}>
                <td>{product.id}</td>
                <td>{product.name}</td>
                <td>
                  <a href={product.checkoutLink} target="_blank" rel="noopener noreferrer">
                    {product.checkoutLink}
                  </a>
                </td>
                <td>
                  <button style={styles.copyButton} onClick={() => copyLink(product.checkoutLink)}>
                    Copiar Link
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <p>Nenhum produto aprovado.</p>
      )}
    </div>
  );
};

const styles = {
  container: {
    maxWidth: '1000px',
    margin: '0 auto',
    padding: '20px',
    backgroundColor: '#f9f9f9',
    borderRadius: '8px',
    boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
  },
  table: {
    width: '100%',
    borderCollapse: 'collapse',
    marginTop: '20px',
  },
  copyButton: {
    backgroundColor: '#007bff',
    color: 'white',
    border: 'none',
    padding: '5px 10px',
    borderRadius: '5px',
    cursor: 'pointer',
  },
};

export default ProductDetails;
