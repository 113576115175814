import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import useTokenValidation from '../../services/checkTokenValidity';

export default function Dashboard() {
  useTokenValidation()
  const [valorLiquido, setValorLiquido] = useState(0);
  const [vendas, setVendas] = useState(0);
  const [aprovacaoCartao, setAprovacaoCartao] = useState(0);
  const [vendasRedeKiwify, setVendasRedeKiwify] = useState(0);
  const [reembolso, setReembolso] = useState(0);
  const [chargeback, setChargeback] = useState(0);
  const [conversaoBoleto, setConversaoBoleto] = useState(0);
  const [boletosGerados, setBoletosGerados] = useState(0);
  
    const navigate = useNavigate()
  const handleNavigation = (path) => {
    navigate(path);
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
      <div>
        <h2>Dashboard</h2>

        {/* Filtros */}
        <div>
          <label htmlFor="periodo">Período: </label>
          <select id="periodo">
            <option value="hoje">Hoje</option>
            <option value="7dias">Últimos 7 dias</option>
            <option value="30dias">Últimos 30 dias</option>
          </select>

          <label htmlFor="produtos">Produtos: </label>
          <select id="produtos">
            <option value="todos">Todos os produtos</option>
            <option value="produto1">Produto 1</option>
            <option value="produto2">Produto 2</option>
          </select>
        </div>

        {/* Métricas */}
        <div>
          <div>
            <h4>Valor líquido</h4>
            <p>R$ {valorLiquido.toFixed(2)}</p>
          </div>
          <div>
            <h4>Vendas</h4>
            <p>{vendas}</p>
          </div>
          <div>
            <h4>Aprovação cartão</h4>
            <p>{aprovacaoCartao}%</p>
          </div>
          <div>
            <h4>Vendas 1-click da rede hestpay</h4>
            <p>R$ {vendasRedeKiwify.toFixed(2)} ({(vendasRedeKiwify / vendas * 100 || 0).toFixed(2)}%)</p>
          </div>
          <div>
            <h4>Reembolso</h4>
            <p>{reembolso}%</p>
          </div>
          <div>
            <h4>Chargeback</h4>
            <p>{chargeback}%</p>
          </div>
          <div>
            <h4>Conversão boleto</h4>
            <p>{conversaoBoleto}%</p>
          </div>
          <div>
            <h4>Boletos gerados</h4>
            <p>{boletosGerados}</p>
          </div>
        </div>
      </div>

      {/* Modal Fixo */}
      <div style={{
        position: 'fixed',
        right: 0,
        top: 0,
        width: '250px',
        height: '100%',
        backgroundColor: '#f1f1f1',
        boxShadow: '-2px 0px 5px rgba(0,0,0,0.5)',
        padding: '20px',
        boxSizing: 'border-box'
      }}>
        <h3>Menu</h3>
        <ul style={{ listStyleType: 'none', padding: 0 }}>
          <li style={{ marginBottom: '10px' }}>
            <button onClick={() => handleNavigation('/financial')}>Financial</button>
          </li>
          <li>
            <button onClick={() => handleNavigation('/products')}>Products</button>
          </li>
        </ul>
      </div>
    </div>
  );
}
