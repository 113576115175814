import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import useTokenValidation from '../../services/checkTokenValidity';

const Profile = () => {
  useTokenValidation()
  const [form, setForm] = useState({
    name: 'Castro.bdy',
    email: 'enzorezende78@gmail.com',
    whatsapp: '(34) 99734-0780',
    cep: '38412-826',
    address: 'Rua Campos Alto',
    number: '40',
    complement: 'ap 1034',
    neighborhood: 'Jardim Holanda',
    city: 'Uberlândia',
    state: 'MG'
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Salvar as alterações aqui
    console.log(form);
  };

  return (
    <div className="profile-container">
      <h2>Meu Perfil</h2>
      <form onSubmit={handleSubmit}>
        <div className="profile-section">
          <label htmlFor="photo">Foto</label>
          <input type="file" id="photo" name="photo" />
        </div>

        <div className="profile-section">
          <label>Nome</label>
          <input
            type="text"
            name="name"
            value={form.name}
            onChange={handleChange}
          />
        </div>

        <div className="profile-section">
          <label>Email</label>
          <input
            type="email"
            name="email"
            value={form.email}
            onChange={handleChange}
          />
          <button type="button">Alterar Email</button>
        </div>

        <div className="profile-section">
          <label>WhatsApp</label>
          <input
            type="text"
            name="whatsapp"
            value={form.whatsapp}
            onChange={handleChange}
          />
        </div>

        {/* Sessão de endereço organizada em coluna */}
        <div className="profile-section">
          <h3>Endereço</h3>
          
          <label>CEP</label>
          <input
            type="text"
            name="cep"
            value={form.cep}
            onChange={handleChange}
          />
          
          <label>Endereço</label>
          <input
            type="text"
            name="address"
            value={form.address}
            onChange={handleChange}
          />
          
          <label>Número</label>
          <input
            type="text"
            name="number"
            value={form.number}
            onChange={handleChange}
          />
          
          <label>Complemento</label>
          <input
            type="text"
            name="complement"
            value={form.complement}
            onChange={handleChange}
          />
          
          <label>Bairro</label>
          <input
            type="text"
            name="neighborhood"
            value={form.neighborhood}
            onChange={handleChange}
          />
          
          <label>Cidade</label>
          <input
            type="text"
            name="city"
            value={form.city}
            onChange={handleChange}
          />
          
          <label>Estado</label>
          <input
            type="text"
            name="state"
            value={form.state}
            onChange={handleChange}
          />
          
          <button type="submit">Salvar Alterações</button>
        </div>
      </form>
    </div>
  );
};

export default Profile;
