import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; 

const useTokenValidation = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const checkTokenValidity = () => {
      // Pegue o token do localStorage
      const token = localStorage.getItem('token');

      if (token) {
        try {
          const decoded = parseJwt(token);
          const currentTime = Date.now() / 1000; // Tempo atual em segundos

          // Verifica se o token expirou
          if (decoded.exp < currentTime) {
            alert('Sessão expirada. Faça login novamente.');
            localStorage.removeItem('token'); // Remove o token expirado
            navigate('/login'); // Redireciona para a página de login
          }
        } catch (error) {
          console.error('Erro ao verificar o token:', error);
          localStorage.removeItem('token'); // Remove o token inválido
          navigate('/login'); // Redireciona para a página de login em caso de erro
        }
      } else {
        // Se não houver token, redireciona para o login
        navigate('/login');
      }
    };

    // Executa a verificação ao carregar o componente
    checkTokenValidity();
  }, [navigate]); // O hook useEffect é chamado sempre que o 'navigate' mudar
};

// Função para decodificar o token JWT manualmente
const parseJwt = (token) => {
  try {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split('')
        .map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join('')
    );
    return JSON.parse(jsonPayload);
  } catch (error) {
    console.error('Erro ao decodificar o token:', error);
    return null;
  }
};

export default useTokenValidation;
